<template>
    <form
        v-show="inNavigation"
        class="search-form-enhanced"
        :class="{'search-form-enhanced--navigation' : inNavigation}"
        @submit.prevent="doSearch"
    >
        <NuxtLink
            :to="!isSearchPage ? '/search' : '/'"
            class="search-form-enhanced__button"
        >
            <template v-if="isSearchPage">
                <BaseIcon
                    icon="close"
                    data-icon="search"
                />
                {{ t('search-close') }}
            </template>
            <template v-else>
                <BaseIcon
                    icon="search"
                    data-icon="search"
                />
                {{ placeholder }}
            </template>
        </NuxtLink>

        <BaseInput
            class="search-form-enhanced__input"
            :model-value="searchQuery"
            name="search"
            :placeholder="placeholder"
            type="search"
            :clearable="false"
            :wrapper-attrs="{ class: inputClass }"
            @update:model-value="searchQuery = $event.target.value"
        >
            <template #after>
                <button
                    key="search"
                    type="button"
                    class="button--clean"
                    aria-label="Search"
                    @click.prevent="doSearch"
                >
                    <BaseIcon
                        icon="search"
                        data-icon="search"
                    />
                </button>
            </template>
        </BaseInput>
    </form>
</template>

<script setup>
const SEARCH_PATH = '/search';
const props = defineProps({
    inNavigation: {
        type: Boolean,
        default: false
    },
    placeholder: {
        type: String,
        default: 'Search',
        required: false
    }
});
const searchQuery = ref('');
const route = useRoute();
const router = useRouter();

const t = useTranslation();

const searchParam = computed(() => route.query.q);
const isSearchPage = computed(() => route.path === SEARCH_PATH);
const inputClass = computed(() => props.inNavigation ? 'dn-input--rounded-secondary search-form-enhanced__input' : 'dn-input--rounded search-form__input');

watch(searchParam, (newVal) => {
    searchQuery.value = newVal;
});

const { pageType } = inject('pageType');
const { trackEvent } = useCustomGtmEvent();
const doSearch = () => {
    trackEvent({
        event: 'search',
        'page_type': pageType?.value ?? null
    });

    router.push({ path: SEARCH_PATH, query: { q: searchQuery.value } });
};

searchQuery.value = searchParam.value;
</script>

<style lang="less">
.search-form-enhanced {
    position: relative;
    line-height: 0;
    width: 100%;

    @media @q-xl-min {
        flex: 1 1 100%;
    }
}

.search-form-enhanced__button {
    display: flex;
    height: 2.1875rem;
    align-items: center;
    white-space: nowrap;
    gap: .5rem;
    line-height: 1;
    background-color: var(--color-alt-light) !important;  /* stylelint-disable-line declaration-no-important */
    color: var(--color-alt-dark);
    font-weight: 700;
    font-family: Spartan, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;
    text-decoration: none;
    font-size: var(--font-size-xs);
    letter-spacing: -1px;
    text-transform: lowercase;
    border-radius: var(--borderRadius-lg);

    padding: 0 1.5rem 0 .85rem;

    html[dir='rtl'] & {
        padding: 0 .85rem 0 1.5rem;
    }

    .dn-icon {
        font-size: 1.25rem;
    }

    @media @q-md-min {
        height: 3rem;
    }

    @media @q-lg-min {
        display: none;
    }
}

.search-form-enhanced__input {
    display: none;
    padding: .2rem .75rem;
    color: var(--color-alt-dark);
    font-weight: 700;
    letter-spacing: -1px;

    input::placeholder {
        font-family: Spartan, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;
        color: var(--color-alt-dark);
        font-size: 1.125rem;
        font-weight: 700;
        letter-spacing: -1px;
    }

    .dn-input__after .dn-icon svg {
        width: 1.5rem;
        height: 1.5rem;
    }

    @media @q-lg-min {
        display: flex;
        align-items: center;
    }
}

// Modifiers
.search-form-enhanced--navigation {
    .dn-input.dn-input--rounded-secondary {
        height: 3rem;
        background-color: var(--color-alt-light) !important;  /* stylelint-disable-line declaration-no-important */
        color: var(--color-alt-dark);
        font-family: Spartan, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;
        font-weight: 700;

        html[dir='rtl'] & {
            padding-right: 2rem;
        }

        &[data-focus='true'] {
            background-color: #fff !important; /* stylelint-disable-line declaration-no-important */
            box-shadow: 0 0 4px 0 #00000017;
        }

        .dn-input__field {
            font-size: var(--font-size-md);
            width: 100%;
        }

        .dn-input__after {
            button {
                color: var(--color-alt-dark);
            }

            @media @q-xl-min {
                width: 3.5rem;
            }
        }

        input[type='search']::-webkit-search-decoration,
        input[type='search']::-webkit-search-cancel-button,
        input[type='search']::-webkit-search-results-decoration {
            -webkit-appearance: none;
        }
    }
}
</style>
